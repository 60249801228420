import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import PostArchives from "../components/PostArchives";
import Pagination from "../components/Pagination";
import { useTranslation } from "react-i18next";
import "../i18n";

const Posts = ({ data, pageContext }) => {
    const { t } = useTranslation();
    const posts = data.allWpPost.nodes;
    const page = data.wpPage;
    const siteTitle = data.wp.generalSettings.title;

    return (
        <Layout>
            <Seo title={`${page.title} | ${siteTitle}`} />

            {/* Header Intro */}
            <section>
                <div className="header-intro theme-bg-primary shadow-sm py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <h2 className="page-heading mb-0">{page.title}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Posts Arhives */}
            <article>
                {posts && posts.length > 0 ? (
                    <PostArchives posts={posts} />
                ) : (
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center mt-5 pt-5">
                                <p className="fs-4">{t("notFound")}</p>
                            </div>
                        </div>
                    </div>
                )}
            </article>

            {/* Pagination */}
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 mb-5">
                            <Pagination pageContext={pageContext} />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Posts;

export const postsQuery = graphql`
    query ($skip: Int!, $limit: Int!) {
        wp {
            generalSettings {
                title
            }
        }
        allWpPost(sort: { date: DESC }, skip: $skip, limit: $limit) {
            nodes {
                date
                databaseId
                excerpt
                uri
                slug
                title
                isSticky
                author {
                    node {
                        name
                    }
                }
                categories {
                    nodes {
                        name
                        link
                    }
                }
                tags {
                    nodes {
                        name
                        link
                    }
                }
                featuredImage {
                    node {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 800
                                    height: 450
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
                    }
                }
            }
        }
        wpPage(slug: { eq: "blog" }) {
            title
        }
    }
`;
